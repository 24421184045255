body {
  padding-bottom: 3rem;
}

.radio-wrapper {
  min-width: 3rem;
}

.z-index-3 {
  z-index: -3;
}

.z-index-2 {
  z-index: -2;
}

.z-index-1 {
  z-index: -1;
}

.vertical-line {
  border-left: 1px solid black;  
  border-right: 1px solid black;  
  align-self: stretch;
}

